import React from 'react';
import { MLabel, MIcon } from 'src/theme';
import { PATH_CUSTOMER } from 'src/routes/paths';


import { Icon } from '@iconify/react';
import bxHome from '@iconify/icons-bx/bx-home';
import bookIcon from '@iconify/icons-akar-icons/book';
import bellFill from '@iconify-icons/eva/bell-fill';
import bxsOffer from '@iconify/icons-bx/bxs-offer';
import boxIcon from '@iconify/icons-la/box';
import fileInvoice from '@iconify/icons-la/file-invoice';
import creditCardOutlined from '@iconify/icons-ant-design/credit-card-outlined';
import moneyBill from '@iconify/icons-fa-solid/money-bill';
import buildingWarehouse from '@iconify/icons-tabler/building-warehouse';
import personIcon from '@iconify/icons-akar-icons/person';
import reportIcon from '@iconify/icons-carbon/report';

import idCard from '@iconify/icons-bx/id-card';
import groupIcon from '@iconify/icons-el/group';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  home: <Icon icon={bxHome} style={{ width: '2em', height: '2em'}}/>,
  userGuide: <Icon icon={bookIcon} style={{ width: '2em', height: '2em'}}/>,
  notifications: <Icon icon={bellFill} style={{ width: '2em', height: '2em'}}/>,
  offers: <Icon icon={bxsOffer} style={{ width: '2em', height: '2em'}}/>,
  preOrder: <Icon icon={boxIcon} style={{ width: '2em', height: '2em'}}/>,
  order: <Icon icon={fileInvoice} style={{ width: '2em', height: '2em'}}/>,
  payment: <Icon icon={creditCardOutlined} style={{ width: '2em', height: '2em'}}/>,
  distributor: <Icon icon={buildingWarehouse} style={{ width: '2em', height: '2em'}}/>,
  report: <Icon icon={reportIcon} style={{ width: '2em', height: '2em'}}/>,
  profile: <Icon icon={personIcon} style={{ width: '2em', height: '2em'}}/>,
  reportIcon: <Icon icon={reportIcon} style={{ width: '2em', height: '2em'}}/>,
  companyAccount: <Icon icon={idCard} style={{ width: '2em', height: '2em' }}/>,
  staffIcon: <Icon icon={groupIcon} style={{ width: '2em', height: '2em' }}/>,
  creditNote: <Icon icon={moneyBill} style={{ width: '2em', height: '2em' }}/>
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'home',
        icon: ICONS.home,
        href: PATH_CUSTOMER.general.root
      },
      {
        title: 'user guide',
        icon: ICONS.userGuide,
        href: PATH_CUSTOMER.general.root
      },
      {
        title: 'notifications',
        icon: ICONS.notifications,
        href: PATH_CUSTOMER.general.notifications,
        badgeModule: 'notification'
      },
      {
        title: 'company profile',
        icon: ICONS.companyAccount,
        href: PATH_CUSTOMER.general.retailerApplicationForm,
        badgeModule: 'companyProfileRetailer'
      }
      /**{
        title: 'company account',
        icon: ICONS.companyAccount,
        href: PATH_CUSTOMER.general.companyAccount
      }*/
    ]
  },
  {
    subheader: 'offers',
    items: [
      {
        title: 'offers',
        icon: ICONS.offers,
        access: 'offers',
        href: PATH_CUSTOMER.general.offers,
        badgeModule: 'offerRetailer'
      },
      {
        title: 'closed offers',
        icon: ICONS.offers,
        access: 'closedOffers',
        href: PATH_CUSTOMER.general.closedOffers
      }
    ]
  },
  {
    subheader: 'pre-order',
    items: [
      {
        title: 'pre-order',
        icon: ICONS.preOrder,
        access: 'preOrder',
        href: PATH_CUSTOMER.general.preOrder,
        badgeModule: 'preOrderRetailer'
      },
      {
        title: 'staff pre-order',
        icon: ICONS.preOrder,
        href: PATH_CUSTOMER.general.staffPreOrder
      }
    ]
  },
  {
    subheader: 'sales',
    items: [
      {
        title: 'all order',
        icon: ICONS.order,
        access: 'allOrder',
        href: PATH_CUSTOMER.general.orderAll,
        badgeModule: 'orderRetailer'
      },
      {
        title: 'orders',
        icon: ICONS.order,
        access: 'orders',
        href: PATH_CUSTOMER.general.order,
        badgeModule: 'orderRetailer'
      },
      {
        title: 'consignment',
        icon: ICONS.order,
        access: 'consignment',
        href: PATH_CUSTOMER.general.consignment,
        badgeModule: 'orderConsignmentRetailer'
      },
      {
        title: 'payment',
        icon: ICONS.payment,
        href: PATH_CUSTOMER.general.payment
      },
      {
        title: 'order issues',
        icon: ICONS.order,
        href: PATH_CUSTOMER.general.orderIssues,
        badgeModule: 'orderIssuesRetailer'
      },
      {
        title: 'credit note',
        icon: ICONS.creditNote,
        href: PATH_CUSTOMER.general.creditNote
      }
    ]
  },
  {
    subheader: 'Consignment MGMT.',
    items: [
      {
        title: 'Stock Returnable',
        icon: ICONS.reportIcon,
        access: 'stockReturnable',
        href: PATH_CUSTOMER.general.consignmentStockReturnable,
        badgeModule: 'consignmentStockReturnableRetailer'
      },
      {
        title: 'report',
        icon: ICONS.reportIcon,
        access: 'consignmentReport',
        href: PATH_CUSTOMER.general.consignmentReport
      },
      {
        title: 'SKU Order',
        icon: ICONS.order,
        access: 'consignmentSKUOrder',
        href: PATH_CUSTOMER.general.consignmentSkuOrder
      },
      {
        title: 'stock products',
        icon: ICONS.order,
        access: 'consignmentStockProducts',
        href: PATH_CUSTOMER.general.stockProducts
      }
    ]
  },
  {
    subheader: 'management',
    items: [
      {
        title: 'distributor',
        icon: ICONS.distributor,
        href: PATH_CUSTOMER.general.distributor,
        badgeModule: 'distributorRetailer'
      },
      {
        title: 'report',
        icon: ICONS.report,
        href: PATH_CUSTOMER.general.report
      },
      {
        title: 'staff',
        icon: ICONS.staffIcon,
        href: PATH_CUSTOMER.general.staff
      },
      {
        title: 'profile',
        icon: ICONS.profile,
        href: PATH_CUSTOMER.general.profile
      }
    ]
  }
];

export default navConfig;

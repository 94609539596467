import { sum, map, filter, uniqBy } from 'lodash';
import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  products: [],
  totalItems: 0,
  totalCost: 0,
  backFromProductDetail: false,
  distributorID: '',
  offerSerial: ''
}

const slice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setProducts(state, action) {
      state.products = action.payload;
    },

    setTotalItems(state, action) {
      state.totalItems = action.payload;
    },

    setTotalCost(state, action) {
      state.totalCost = action.payload;
    },

    resetCart(state) {
      state.products = []
      state.totalItems = 0
      state.totalCost = 0
      state.backFromProductDetail = false
      state.distributorID = ''
      state.offerSerial = ''
    },

    setBackFromProductDetail(state, action) {
      state.backFromProductDetail = action.payload;
    },

    setDistributorID(state, action) {
      state.distributorID = action.payload;
    },

    setOfferSerial(state, action) {
      state.offerSerial = action.payload;
    }
  }
})

// Reducer
export default slice.reducer;

// Actions
export const {
} = slice.actions;

// ----------------------------------------------------------------------

export function setProducts(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setProducts(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setTotalItems(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setTotalItems(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setTotalCost(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setTotalCost(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

// ----------------------------------------------------------------------
export function resetCart() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.resetCart())
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}


export function setBackFromProductDetail(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setBackFromProductDetail(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setDistributorID(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setDistributorID(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setOfferSerial(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setOfferSerial(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}
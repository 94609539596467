import React from 'react';
import { MLabel, MIcon } from 'src/theme';
import { PATH_ADMIN, PATH_ADMIN_KC } from 'src/routes/paths';

import HomeIcon from '@material-ui/icons/Home';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/free-solid-svg-icons';

import { Icon } from '@iconify/react';
import bxHome from '@iconify/icons-bx/bx-home';
import peopleGroup from '@iconify/icons-akar-icons/people-group';
import personIcon from '@iconify/icons-akar-icons/person';
import buildingWarehouse from '@iconify/icons-tabler/building-warehouse';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  home: <Icon icon={bxHome} style={{ width: '2em', height: '2em'}}/>,
  distributor: <Icon icon={buildingWarehouse} style={{ width: '2em', height: '2em'}}/>
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    //subheader: 'general',
    items: [
      {
        title: 'home',
        icon: ICONS.home,
        href: PATH_ADMIN_KC.general.root
      },
      {
        title: 'distributor',
        icon: ICONS.distributor,
        href: PATH_ADMIN_KC.general.distributor
      }
    ]
  }
];

export default navConfig;

import React, { lazy } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import HomeLayout from 'src/layouts/HomeLayout';
import { PATH_PAGE } from './paths';

// ----------------------------------------------------------------------

const HomeRoutes = {
  path: '*',
  layout: HomeLayout,
  routes: [
    {
      exact: true,
      path: '/',
      component: lazy(() => import('src/views/home-new-v3/LandingPageView/index'))
    },
    {
      exact: true,
      path: '/forgot-password',
      component: lazy(() => import('src/views/home-new-v3/ForgotPasswordView/indexForgotPasword'))
    },
    {
      exact: true,
      path: '/password-reset/:email',
      component: lazy(() => import('src/views/home-new-v3/ForgotPasswordView/indexResetPassword'))
    },
    /**{
      exact: true,
      path: '/sign-up',
      component: lazy(() => import('src/views/home/LandingPageView/SignUp'))
    },*/
  
    {
      exact: true,
      path: '/register-success/:email',
      component: lazy(() => import('src/views/home/LandingPageView/RegisterSuccess'))
    },
    {
      exact: true,
      path: '/components',
      component: lazy(() => import('src/views/home/ComponentsView'))
    },
    // NEW ROUTES - V3
    {
      exact: true,
      path: '/sign-up/:distributorCompany',
      component: lazy(() => import('src/views/home-new-v3/SignUpFormView/indexSignUp'))
    },
    {
      exact: true,
      path: '/terms-of-use',
      component: lazy(() => import('src/views/home-new-v3/TermsOfUseView/TermsOfUse')) 
    },
    {
      exact: true,
      path: '/privacy-policy',
      component: lazy(() => import('src/views/home-new-v3/PrivacyPolicyView/PrivacyPolicy'))
    },
    // {
    //   exact: true,
    //   path: '/register-success/:email/:distributorID',
    //   component: lazy(() => import('src/views/home-new-v3/RegisterSuccess'))
    // },
    {
      exact: true,
      path: '/customer/pre-order/view/:preOrderID',
      component: RedirPreOrder
    },
    {
      exact: true,
      path: '/customer/offers/view/:offerID',
      component: RedirOffer
    },
    {
      exact: true,
      path: '/customer/invoice/:invoiceID',
      component: RedirInvoice
    },
    {
      exact: true,
      path: '/customer/closed-offers/view/:offerID',
      component: RedirClosedOffer
    },

    // VERIFY ACCOUNT FROM CLICK LINK IN THE EMAIL
    {
      exact: true,
      path: '/verify-account/:email/:signUpToDistributor/:accessCode',
      component: lazy(() => import('src/views/home-new-v3/VerifyAccountView/indexVerifyAccount'))
    },

    // CUSTOM RESET PASSWORD 
    {
      exact: true,
      path: '/form-reset-password',
      component: lazy(() => import('src/views/home-new-v3/ForgotPasswordView/indexFormResetPassword'))
    }
  ]
};

function RedirPreOrder() {
  const location = useLocation()
  localStorage.setItem('preOrderDirect', location.pathname)
  return <Redirect to={PATH_PAGE.auth.homepage}/>
}

function RedirOffer() {
  const location = useLocation()
  localStorage.setItem('offerDirect', location.pathname)
  return <Redirect to={PATH_PAGE.auth.homepage}/>
}

function RedirInvoice() {
  const location = useLocation()
  localStorage.setItem('invoiceDirect', location.pathname)
  return <Redirect to={PATH_PAGE.auth.homepage}/>
}

function RedirClosedOffer() {
  const location = useLocation()
  localStorage.setItem('closedOfferDirect', location.pathname)
  return <Redirect to={PATH_PAGE.auth.homepage}/>
}


export default HomeRoutes;

import { PATH_CUSTOMER, PATH_CUSTOMER_MOBILE, PATH_CUSTOMER_MOBILE_APP_REVIEW } from "./paths";
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardCustomerMobAppReviewLayout from "src/layouts/DashboardCustomerMobAppReviewLayout";

// ----------------------------------------------------------------------
const AppCustomerMobAppReview = {
  path: PATH_CUSTOMER_MOBILE_APP_REVIEW.root,
  guard: AuthProtect,
  layout: DashboardCustomerMobAppReviewLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------

    // PROFILE
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE_APP_REVIEW.general.profile,
      component: lazy(() => import('src/views/CustomerMobileAppReviewGroup/profile/Profile'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE_APP_REVIEW.general.editProfile,
      component: lazy(() => import('src/views/CustomerMobileAppReviewGroup/profile/EditProfile'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE_APP_REVIEW.general.changeEmail,
      component: lazy(() => import('src/views/CustomerMobileAppReviewGroup/profile/ChangeEmail'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE_APP_REVIEW.general.changePassword,
      component: lazy(() => import('src/views/CustomerMobileAppReviewGroup/profile/ChangePassword'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE_APP_REVIEW.general.verificationCode,
      component: lazy(() => import('src/views/CustomerMobileAppReviewGroup/profile/VerificationCode'))
    },

    // RETAILER APPLICATION FORM
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE_APP_REVIEW.general.retailerApplicationForm,
      component: lazy(() => import('src/views/CustomerMobileAppReviewGroup/retailer-application-form/RetailerApplicationForm'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE_APP_REVIEW.general.retailerApplicationFormReApply.form001,
      component: lazy(() => import('src/views/CustomerMobileAppReviewGroup/retailer-application-form/Form001'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE_APP_REVIEW.general.retailerApplicationFormReApply.form002,
      component: lazy(() => import('src/views/CustomerMobileAppReviewGroup/retailer-application-form/Form002'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE_APP_REVIEW.general.retailerApplicationFormReApply.form003,
      component: lazy(() => import('src/views/CustomerMobileAppReviewGroup/retailer-application-form/Form003'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE_APP_REVIEW.general.retailerApplicationFormReApply.form004,
      component: lazy(() => import('src/views/CustomerMobileAppReviewGroup/retailer-application-form/Form004'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE_APP_REVIEW.general.retailerApplicationFormReApply.form005,
      component: lazy(() => import('src/views/CustomerMobileAppReviewGroup/retailer-application-form/Form005'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE_APP_REVIEW.general.retailerApplicationFormReApply.formSummary,
      component: lazy(() => import('src/views/CustomerMobileAppReviewGroup/retailer-application-form/FormSummary'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE_APP_REVIEW.general.retailerApplicationFormReApply.formAgreement,
      component: lazy(() => import('src/views/CustomerMobileAppReviewGroup/retailer-application-form/FormAgreementTermDistributor'))
    },
    

    // DISTRIBUTOR
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE_APP_REVIEW.general.distributor,
      component: lazy(() => import('src/views/CustomerMobileAppReviewGroup/distributor/Distributor'))
    },
    {
      exact: true,
      path: PATH_CUSTOMER_MOBILE_APP_REVIEW.general.distributorDetail,
      component: lazy(() => import('src/views/CustomerMobileAppReviewGroup/distributor/DistributorView'))
    }
  ]
}

export default AppCustomerMobAppReview
import axios from 'axios';
import queryString from 'query-string';

const server_url = process.env.REACT_APP_SERVER_URL;

const customerUpdateNotRequiredActionByModule = async (token, sendData) => {
  return await axios
    .post(`${server_url}/api/badgeAction/customer/updateNotRequiredActionByModule`, sendData, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

const customerCreateBadgeActionForDistributor = async (token, sendData) => {
  return await axios
    .post(`${server_url}/api/badgeAction/customer/createBadgeActionForDistributor`, sendData, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

const customerCreateBadgeActionForAdminKC = async (token, sendData) => {
  return await axios
    .post(`${server_url}/api/badgeAction/customer/createBadgeActionForAdminKC`, sendData, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

const getCountBadgeActionForRetailer = async (token, params) => {
  const query = queryString.stringify(params)
  return await axios
    .get(`${server_url}/api/badgeAction/customer/getCountBadgeActionForRetailer?${query}`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
    })
}

const getBadgeActionForRetailerByModule = async (token, params) => {
  const query = queryString.stringify(params)
  return await axios
    .get(`${server_url}/api/badgeAction/customer/getBadgeActionForRetailerByModule?${query}`, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      // console.log(response)
      return response.data
    })
    .catch(function (error) {
      return error.response
  })
}

const updateViewBadgeActionByModule = async (token, sendData) => {
  return await axios
    .post(`${server_url}/api/badgeAction/customer/updateViewBadgeActionByModule`, sendData, {
      headers: { authorization: `Bearer ${token}` }
    })
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

export {
  customerUpdateNotRequiredActionByModule,
  customerCreateBadgeActionForDistributor,
  customerCreateBadgeActionForAdminKC,
  getCountBadgeActionForRetailer,
  getBadgeActionForRetailerByModule,
  updateViewBadgeActionByModule
}
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  firstName: '',
  lastName: '',
  email: '',
  confirmEmail: '',
  country: '',
  phoneNo: '',
  password: '',
  confirmPassword: '',
  isBackFromReadTerms: false
}

const slice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setFirstName(state, action) {
      state.isLoading = false;
      state.firstName = action.payload
    },

    setLastName(state, action) {
      state.isLoading = false;
      state.lastName = action.payload
    },

    setEmail(state, action) {
      state.isLoading = false;
      state.email = action.payload
    },

    setConfirmEmail(state, action) {
      state.isLoading = false;
      state.confirmEmail = action.payload
    },
    
    setCountry(state, action) {
      state.isLoading = false;
      state.country = action.payload
    },
   
    setPhoneNo(state, action) {
      state.isLoading = false;
      state.phoneNo = action.payload
    },

    setPassword(state, action) {
      state.isLoading = false;
      state.password = action.payload
    },
    
    setConfirmPassword(state, action) {
      state.isLoading = false;
      state.confirmPassword = action.payload
    },

    setIsBackFromReadTerms(state, action) {
      state.isLoading = false;
      state.isBackFromReadTerms = action.payload
    },

    resetSignUpForm(state) {
      state.isLoading = false
      state.firstName = ''
      state.lastName = ''
      state.email = ''
      state.confirmEmail = ''
      state.country = ''
      state.phoneNo = ''
      state.password = ''
      state.confirmPassword = ''
      state.isBackFromReadTerms = false
    }
  }
})

// Reducer
export default slice.reducer;

// Actions
export const {
} = slice.actions;

// ----------------------------------------------------------------------
export function setFirstName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setFirstName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setLastName(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setLastName(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setEmail(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setEmail(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setConfirmEmail(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setConfirmEmail(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setCountry(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setCountry(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setPhoneNo(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setPhoneNo(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setPassword(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setPassword(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setConfirmPassword(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setConfirmPassword(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function setIsBackFromReadTerms(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.setIsBackFromReadTerms(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}

export function resetSignUpForm(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      dispatch(slice.actions.resetSignUpForm(value))
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error))
    }
  }
}
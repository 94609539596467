import { PATH_ADMIN } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardAdminLayout from 'src/layouts/DashboardAdminLayout';

// ----------------------------------------------------------------------

const AppAdminRoutes = {
  path: PATH_ADMIN.root,
  guard: AuthProtect,
  layout: DashboardAdminLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------

    // DASHBOARD
    {
      exact: true,
      path: PATH_ADMIN.general.dashboard,
      component: lazy(() => import('src/views/AdminDashboardGroup/dashboard/DashboardAppView'))
    },

    // ROLE
    {
      exact: true,
      path: PATH_ADMIN.general.role,
      component: lazy(() => import('src/views/AdminDashboardGroup/role/Role'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.addRole,
      component: lazy(() => import('src/views/AdminDashboardGroup/role/AddRole'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.roleDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/role/details/RoleView'))
    },

    // USER
    {
      exact: true,
      path: PATH_ADMIN.general.user,
      component: lazy(() => import('src/views/AdminDashboardGroup/user/User'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.addUser,
      component: lazy(() => import('src/views/AdminDashboardGroup/user/AddUser'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.userDetails.view,
      component: lazy(() => import('src/views/AdminDashboardGroup/user/details/UserView'))
    },

    // DISTRIBUTOR
    {
      exact: true,
      path: PATH_ADMIN.general.distributor,
      component: lazy(() => import('src/views/AdminDashboardGroup/distributor/Distributor'))
    },
    {
      exact: true,
      path: PATH_ADMIN.general.addDistributor,
      component: lazy(() => import('src/views/AdminDashboardGroup/distributor/AddDistributor'))
    }
  ]
}

export default AppAdminRoutes